<template>
    <div class="calendar">
        <div class="calendar-header">
            <carousel :mouseDrag="sliderTouchDrag" :touchDrag="sliderTouchDrag" :autoplay="false" :loop="false"
                :nav="false" :margin="5" :dots="false" :items="4" :slideBy="7" :autoplayHoverPause="true"
                :responsive="{ 1900: { items: 10}, 1360: { items: 10}, 1024: { items: 10}, 768: { items: 2 }, 320: { items: 1 } }"
                v-once v-model="displayDays">
                <template slot="prev"><span class="slider_arrow slider_prev"><i class="fa fa-angle-left"
                            aria-hidden="true"></i></span></template>
                <div class="carousel_item" v-for="(day, index) in displayDays" :key="index">
                    <span @click="selectDay(day, index)" class="day_box" :class="{
                        'current-day': isCurrentDay(day),
                        'disabled-day': isDisabledDay(day)
                    }" :id="'day_box' + index">
                        <span v-html="formatDay(day)"></span>
                    </span>
                </div>
                <template slot="next"><span class="slider_arrow slider_next"><i class="fa fa-angle-right"
                            aria-hidden="true"></i></span></template>
            </carousel>
        </div>
        <div class="time_slots" v-if="selectedDate">
            <div v-if="morningSlots.length">
                <h3 class="time_slot_heading">Morning Slots</h3>
                <span v-for="slot in morningSlots" :key="slot" @click="selectSlot(slot)" class="time_box"  :class="{ 'active-slot': isActiveSlot(slot) }">
                    {{ formatTime(slot) }}
                </span>
            </div>
            <div v-if="afternoonSlots.length">
                <h3 class="time_slot_heading">Afternoon Slots</h3>
                <span v-for="slot in afternoonSlots" :key="slot" @click="selectSlot(slot)" class="time_box" :class="{ 'active-slot': isActiveSlot(slot) }">
                    {{ formatTime(slot) }}
                </span>
            </div>
            <div v-if="eveningSlots.length">
                <h3 class="time_slot_heading">Evening Slots</h3>
                <span v-for="slot in eveningSlots" :key="slot" @click="selectSlot(slot)" class="time_box" :class="{ 'active-slot': isActiveSlot(slot) }">
                    {{ formatTime(slot) }}
                </span>
            </div>
            <div v-if="!availableSlots.length">No available slots for the selected day</div>
        </div>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel';

export default {
    props: {
        value: {
            type: String,
        },
        schedule: {
            type: Object,
        }
    },
    components: {
        carousel
    },
    data() {
        return {
            sliderTouchDrag: false,
            currentDate: new Date(),
            selectedDate: null,
            selectedSlot: null,
            selectedDateTime: '',
            isSelectedDayState: ''
        }
    },
    computed: {
        displayDays() {
            const days = [];
            const startDate = new Date(this.currentDate);
            for (let i = 0; i < 30; i++) {
                const day = new Date(startDate);
                day.setDate(startDate.getDate() + i);
                days.push(day);
            }
            return days;
        },
        availableSlots() {
            if (!this.selectedDate) return [];
            const selectedDay = this.selectedDate.toLocaleString('en-us', { weekday: 'long' });
            const weekNumber = this.getRepeatingWeekNumber(this.selectedDate);
            const monthWeek = `week_${weekNumber}`;

            let daySchedule;
            if (this.schedule[monthWeek]) {
                daySchedule = this.schedule[monthWeek].find(s => s.day === selectedDay);
            }

            if (!daySchedule) return [];

            const slots = [];
            const start = new Date(`1970-01-01T${daySchedule.start_time}:00`);
            const end = new Date(`1970-01-01T${daySchedule.end_time}:00`);
            while (start < end) {
                slots.push(start.toTimeString().slice(0, 5));
                start.setMinutes(start.getMinutes() + 30);
            }
            return slots;
        },
        morningSlots() {
            return this.availableSlots.filter(slot => {
                const hour = parseInt(slot.split(':')[0]);
                return hour >= 0 && hour < 12;
            });
        },
        afternoonSlots() {
            return this.availableSlots.filter(slot => {
                const hour = parseInt(slot.split(':')[0]);
                return hour >= 12 && hour < 17;
            });
        },
        eveningSlots() {
            return this.availableSlots.filter(slot => {
                const hour = parseInt(slot.split(':')[0]);
                return hour >= 17;
            });
        }
    },
    methods: {
        formatDay(date) {
            const options = { weekday: 'short', day: 'numeric' };
            const formatted = date.toLocaleDateString('en-US', options);
            const [dayName, dayNumber] = formatted.split(' ');
            return `${dayName}<br>${dayNumber}`;
        },
        formatTime(time) {
            return new Date(`1970-01-01T${time}:00`).toLocaleTimeString('en-US',
                { hour: 'numeric', minute: '2-digit' }
            );
        },
        isCurrentDay(day) {
            const today = new Date();
            return day.toDateString() === today.toDateString();
        },
        isSelectedDay(day) {
            return this.selectedDate && day.toDateString() === this.selectedDate.toDateString();
        },
        getRepeatingWeekNumber(date) {
            const weekNumber = this.getWeekNumber(date);
            const scheduleWeeks = Object.keys(this.schedule).length;
            if (scheduleWeeks === 1) {
                return 1; // If only week_1 exists, always return 1
            } else if (scheduleWeeks === 2) {
                return weekNumber % 2 === 1 ? 1 : 2; // Alternate between week 1 and 2
            } else {
                return ((weekNumber - 1) % scheduleWeeks) + 1; // Cycle through all available weeks
            }
        },

        isDisabledDay(day) {
            const selectedDay = day.toLocaleString('en-us', { weekday: 'long' });
            const weekNumber = this.getRepeatingWeekNumber(day);
            const monthWeek = `week_${weekNumber}`;

            let hasSchedule = false;
            if (this.schedule[monthWeek]) {
                hasSchedule = this.schedule[monthWeek].some(s => s.day === selectedDay);
            }

            return !hasSchedule;
        },
        selectDay(day, index) {
            if (!this.isDisabledDay(day)) {
                this.selectedDate = day;
                this.selectedSlot = null;
                this.updateSelectedDateTime();
                this.isSelectedDayState = this.selectedDate;
                var elements = document.querySelectorAll('.day_box');
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('selected-day');
                }
                document.getElementById('day_box' + index).classList.add('selected-day');
            }
        },
        selectSlot(slot) {
            this.selectedSlot = slot;
            this.updateSelectedDateTime();
        },
        isActiveSlot(slot) {
            return this.selectedSlot === slot;
        },
        getWeekNumber(date) {
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            const daysSinceFirstDay = Math.floor((date - firstDayOfMonth) / (24 * 60 * 60 * 1000));
            return Math.floor(daysSinceFirstDay / 7) + 1;
        },

        updateSelectedDateTime() {
            if (this.selectedDate && this.selectedSlot) {
                this.selectedDateTime = this.formatDateTime(this.selectedDate, this.selectedSlot);
                this.$emit('input', this.selectedDateTime);
            } else {
                this.selectedDateTime = '';
                this.$emit('input', '');
            }
        },
        formatDateTime(date, time) {
            const [hours, minutes] = time.split(':');
            const dateObj = new Date(date);
            dateObj.setHours(parseInt(hours, 10), parseInt(minutes, 10));

            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            const formattedHours = String(dateObj.getHours()).padStart(2, '0');
            const formattedMinutes = String(dateObj.getMinutes()).padStart(2, '0');

            // Get timezone offset
            const tzOffset = -dateObj.getTimezoneOffset();
            const tzOffsetHours = String(Math.floor(Math.abs(tzOffset) / 60)).padStart(2, '0');
            const tzOffsetMinutes = String(Math.abs(tzOffset) % 60).padStart(2, '0');
            const tzSign = tzOffset >= 0 ? '+' : '-';

            return `${year}-${month}-${day}T${formattedHours}:${formattedMinutes}:00${tzSign}${tzOffsetHours}:${tzOffsetMinutes}`;
        }
    }
}
</script>

<style scoped lang="scss">
.calendar {
    width: 100%;
    position: relative;
    padding-bottom: 15px;
    color: #494949;

    .calendar-header {
        position: relative;
        padding: 0 15px;

        .carousel_item {
            padding: 5px 0;
        }

        .day_box {
            cursor: pointer;
            padding:2px 5px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            display: block;
            font-size: 14px;

            &:hover {
                color: #ffd400;
                border-color: #ffd400;
            }

            &.selected-day {
                background-color: #261139;
                color: #a560e8;
                border-color: #a560e8;
            }

            &.disabled-day {
                opacity: 0.5;
                cursor: not-allowed;

                &:hover {
                    color: rgba(255, 255, 255, 0.5);
                    border-color: rgba(255, 255, 255, 0.3);
                }
            }
        }
    }

    .slider_arrow {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: -8px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);

        .fa {
            cursor: pointer;
            font-size: 28px;
            line-height: 28px;
            color: #ffc107;

            &:hover {
                color: #87cefa;
            }
        }

        &.slider_next {
            right: -8px;
            left: auto;
        }
    }

    .time_slots {
        padding: 12px 15px 0px;

        .time_slot_heading {
            font-size: 18px;
            margin-bottom: 4px;
            color: #ffc107;
            line-height: 1;
        }

        .time_box {
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, 0.05);
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            display: inline-block;
            font-size: 14px;
            margin-right: 10px;
            margin-top:4px;

            &:hover {
                color: #ffd400;
                border-color: #ffd400;
            }
            &.active-slot{
                background-color: #261139;
                color: #a560e8;
                border-color: #a560e8;
            }
        }
    }
}
</style>