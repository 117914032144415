<template>
    <div class="popup_scroll_wrp">
        <div class="rar_popup_wrp">
            <div class="rar_popup_modal_center">
                <div class="rar_popup_modal">
                    <div class="rar_popup_header">
                        <span class="rar_popup_close"><i class="fa fa-times-circle-o" aria-hidden="true"
                                @click="feedbackPopupCloseMethod"></i></span>
                    </div>
                    <div class="rar_popup_body">
                        <h3 class="form_heading">Share your feedback</h3>
                        <form @submit.prevent="feedbackFormBeforeSubmit">
                            <div class="form-group">
                                <label>How would you rate your exeperience?</label>
                                <star-rating :rating.sync="currentRating" size="20"
                                    :star-type="'fa fa-star'" :increment="0.5" v-model="currentRating"/>
                                <input type="text" class="form-control" v-model="currentRating" rows="4"
                                    v-validate="'required'" v-bind:class="{ 'is-invalid': errors.has('currentRating') }"
                                    name="currentRating" data-vv-as="current rating" hidden>
                                <small class="invalid-feedback" v-if="errors.has('currentRating')">{{
                                    errors.first('currentRating')}}</small>
                            </div>
                            <div class="form-group">
                                <label>Tell us about your exeperience</label>
                                <textarea type="text" class="form-control" v-model="comment" rows="4"
                                    v-validate="'required'" v-bind:class="{ 'is-invalid': errors.has('comment') }"
                                    name="comment" data-vv-as="comment"></textarea>
                                <small class="invalid-feedback" v-if="errors.has('comment')">{{ errors.first('comment')
                                    }}</small>
                            </div>
                            <div class="btn-row">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import StarRating from 'vue-star-rating';
import toast from '@/services/toast';
import { bus } from '@/main';
export default {
    props: ['doctorID'],
    components: {
        StarRating, // Register the component
    },
    data() {
        return {
            currentRating:0,
            comment: '',
            submitted:false
        };
    },
    mounted(){
        this.$store.dispatch('getConsultDoctorsListAction')
    },
    methods: {
        feedbackPopupCloseMethod() {
            bus.$emit('feedbackStateBus', false)
            document.body.classList.remove('popup_open_body')
        },
        feedbackFormBeforeSubmit() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    let data = {
                        doctor: this.doctorID,
                        review: this.comment,
                        rating: this.currentRating,
                    }
                    this.$store.dispatch('doctorFeedbackPostAction', data).then((res) => {
                        toast.success(res.data.msg);
                        this.$store.dispatch('getAppointmentListApiFun');
                        this.feedbackPopupCloseMethod()
                        this.submitted = false
                    }).catch((err) => {
                        toast.error(err.data.msg);
                        this.submitted = false
                    })
                    return;

                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import url("../../../assets/fonts/simplicity/stylesheet.css");

.popup_scroll_wrp {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.92);
    width: 100%;
    font-family: 'MyriadProRegular', 'Lato', sans-serif;
    color: #ffffff;
    min-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #333333;
    }

    .rar_popup_wrp {
        display: table;
        width: 100%;
        height: 100%;

        .rar_popup_modal_center {
            display: table-cell;
            vertical-align: middle;
        }

        .rar_popup_modal {
            background: #000;
            max-width: 540px;
            margin: 0 auto;
            border-radius: 5px;
            box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
        }

        .rar_popup_close {
            position: absolute;
            top: 15px;
            right: 10px;
            line-height: 1;

            .fa {
                font-size: 28px;
                color: red;
                cursor: pointer;
                transform: scale(1);
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -webkit-transition: all ease 0.5s;
                transition: all ease 0.5s;

                &:hover {
                    color: #87cefa;
                }
            }
        }

        .rar_popup_header {
            padding: 15px 0px 0px;
            position: relative;
            // border-bottom: 1px solid rgba(255, 255, 255, 0.2);

            .rar_popup_heading {
                font-size: 24px;
                letter-spacing: 0;
                margin-bottom: 0;
            }
        }

        .rar_popup_body {
            padding: 30px 40px;

            .form_heading {
                font-size: 40px;
                color: #fffcc4;
                margin-bottom: 30px;
                @media only screen and (max-width:767px){font-size:30px;}
            }

            label {
                color: #afafaf;
                font-size: 20px;
                letter-spacing: 0;
                line-height: 1;
                margin-bottom: 10px;
                @media only screen and (max-width:767px){font-size:16px;}
            }

            .fa-star {
                font-size: 48px;
                margin-right: 10px;
            }

            .btn-primary {
                background: #ffc107;
                border: 2px solid #ffc107 !important;
                font-size: 26px;
                padding: 10px 20px;
                line-height: 1;
                height: auto;
                border-radius: 4px;
                box-shadow: none !important;
                outline: none !important;
                width: 100%;
                color: #000000;
            }

            .form-group {
                margin-bottom: 30px;
            }

            .form-control {
                background: rgba(255, 255, 255, 0.08);
                font-size: 18px;
                color: #ffd400;
                font-family: MyriadProRegular, Lato, sans-serif;
                border: 1px solid rgba(255, 255, 255, 0.1);
                width: 100%;
                line-height: 1;
                padding: 15px 12px;
                height: auto;
                box-shadow: none;

                option {
                    color: #000000;
                }

                &::-webkit-input-placeholder {
                    color: #686868;
                }

                &::-moz-placeholder {
                    color: #686868;
                }

                &::-ms-input-placeholder {
                    color: #686868;
                }

                &:focus::-webkit-input-placeholder {
                    font-size: 0px;
                }

                &:focus::-moz-placeholder {
                    font-size: 0px;
                }

                &:focus::-ms-input-placeholder {
                    font-size: 0px;
                }
            }
        }
    }
}
</style>